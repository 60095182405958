
export async function switchChain(chainId, web3, callback, dispatchNewChainId) {
    console.log('Try switch to chain: ' + chainId)

    try {
        await window.ethereum.request({
            method: 'wallet_switchEthereumChain',
            params: [{ chainId: chainId }],
        });
    } catch (switchError) {
        console.log(switchError)
        // This error code indicates that the chain has not been added to MetaMask.
        if (switchError.code === 4902 || switchError.code === -32603) {
            if (chainId === '0x63564c40')
                addHarmonyNetwork(web3, callback)
            if (chainId === '0x686')
                addFindoraNetwork(web3, callback)
        }
        // handle other "switch" errors
        callback(JSON.stringify(switchError));
    }
    dispatchNewChainId(chainId)
}

export async function addHarmonyNetwork(web3, callback) {
    try {
        await window.ethereum.request({
            method: 'wallet_addEthereumChain',
            params: [
                {
                    chainId: '0x63564C40',
                    chainName: 'Harmony Mainnet',
                    nativeCurrency: {
                        name: 'ONE',
                        symbol: 'ONE',
                        decimals: 18,
                    },
                    rpcUrls: ['https://api.harmony.one'],
                    blockExplorerUrls: ['https://explorer.harmony.one/'],
                },
            ],
        });
    } catch (addError) {
        // handle "add" error
        callback(JSON.stringify(addError));
    }
}

export async function addFindoraNetwork(web3, callback) {
    try {
        await window.ethereum.request({
            method: 'wallet_addEthereumChain',
            params: [
                {
                    chainId: '0x868',
                    chainName: 'Findora Mainnet',
                    nativeCurrency: {
                        name: 'FRA',
                        symbol: 'FRA',
                        decimals: 18,
                    },
                    rpcUrls: ['https://prod-mainnet.prod.findora.org:8545'],
                    blockExplorerUrls: ['https://blockscout.findorascan.io/'],
                },
            ],
        });
    } catch (addError) {
        // handle "add" error
        callback(JSON.stringify(addError));
    }
}