import { formatCurrency, formatONE } from '../utils/numberFormat';
import { RampInstantSDK } from '@ramp-network/ramp-instant-sdk';
import { useEffect, useState } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { isMobile } from "react-device-detect";
import { HarmonyAddress } from "@harmony-js/crypto";
import { useSelector, useDispatch } from 'react-redux';

const buyOnClick = (addr) => {
    const variant = (isMobile ? 'mobile' : 'desktop');
    new RampInstantSDK({
        hostAppName: 'DK Validator - Stake',
        hostLogoUrl: 'https://app.dkvalidator.one/logo.PNG',
        userAddress: new HarmonyAddress(addr).bech32,
        defaultAsset: 'Harmony_ONE',
        hostApiKey: 'gv26xrjc6tfksorkgcxruc5mzg42tzyvug8sqv7s',
        variant: variant,
    }).show();
};

const AccountStats = ({ showBalance }) => {
    const { ethereumAddress, totalStake, balance, totalUndelegating, symbol, isHarmony } = useSelector((state) => state.wallet);
    const { currency, exchangeRate } = useSelector((state) => state.currency);
    const [accountBalance, setAccounBalance] = useState(0);
    const dispatch = useDispatch();

    useEffect(() => {
        const total = Number(totalStake) + Number(balance) + Number(totalUndelegating);
        if (total !== accountBalance)
            setAccounBalance(total);

    }, [balance, totalStake, accountBalance, totalUndelegating, dispatch])

    return (
        <div className="column">
            <div className="box">
                <h3 onClick={() => showBalance()}>Total Balance <FontAwesomeIcon className='click-me' icon={solid('magnifying-glass-dollar')} /></h3>
                <h2>{formatONE(accountBalance)} {symbol}</h2>
                <h4>{formatCurrency(accountBalance * exchangeRate, currency)}</h4>
                <h3>Amount Staked</h3>
                <h2>{formatONE(totalStake)} {symbol}</h2>
                <h4>{formatCurrency(totalStake * exchangeRate, currency)}</h4>
                {isHarmony &&
                    <button className="btn" onClick={() => buyOnClick(ethereumAddress)}><FontAwesomeIcon icon={solid('credit-card')} /> Buy</button>
                }
            </div>
        </div>
    )
}

export default AccountStats
