import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { useSelector } from 'react-redux';
import { QRCodeSVG } from 'qrcode.react';
import ReactTooltip from 'react-tooltip';


const Account = ({ close }) => {
    const { ensName, ethereumAddress, harmonyAddress } = useSelector((state) => state.wallet);
    const [hmyAddr, setHmyAddr] = useState(true);

    const copyToClipBoard = (value) => {
        navigator.clipboard.writeText(value);
    }

    return (
        <>
            <div className='overlay-header' style={{ color: "white", backgroundColor: "black", border: "2px solid black", borderTopLeftRadius: "20px", borderTopRightRadius: "20px" }}>
                <div style={{ width: "23%", display: "inline-block" }}>

                </div>
                <div style={{ textAlign: "center", width: "50%", display: "inline-block", color: "#00AEE9" }}>
                    <h3>Account Details</h3>
                </div>
                <div style={{ width: "23%", display: "inline-block", padding: "5px" }}>
                    <FontAwesomeIcon size="xl" style={{ cursor: "pointer", color: "##FF6F00", float: "right" }} icon={solid('circle-xmark')} onClick={() => close()} />
                </div>
            </div>
            <div style={{ content: "", display: "table", clear: "both" }}></div>

            <div id='overlay-content' style={{ textAlign: "center", border: "3px solid black", backgroundColor: "rgb(57, 54, 54)", color: "white" }}>
                {ensName !== '' && (
                    <div style={{ textAlign: "centre", padding: "5px" }}>{ensName}</div>
                )}
                {/*<p style={{textAlign: "left", padding: "5px"}}>Harmony supports two address formats; the native Harmony one1x format is used by Crypto Exchanges and the Ethereum 0x address format used for Etherm compatibility.</p>*/}
                <div style={{ textAlign: "left", padding: "5px" }}>Select address format:</div>
                <input type='radio' id='harmony' name='address-format' value='Harmony' defaultChecked onChange={() => setHmyAddr(true)} />
                <label htmlFor='harmony'>Harmony</label>
                <input type='radio' id='ethereum' name='address-format' value='Ethereum' onChange={() => setHmyAddr(false)} />
                <label htmlFor='ethereum'>Ethereum</label>
                {hmyAddr ? (
                    <>
                        <div style={{ textAlign: "left", padding: "5px" }}>Harmony Address:</div>
                        <div style={{ textAlign: "left", padding: "5px", fontSize: "small", color: "#00AEE9" }}>{harmonyAddress}<FontAwesomeIcon size="sm" style={{ cursor: "pointer", color: "##FF6F00", float: "right" }} icon={solid('copy')} onClick={() => copyToClipBoard(harmonyAddress)} data-tip='Copied!' data-event='click focus' /></div>
                        <br />
                        <div style={{ padding: "5px", backgroundColor: "white" }}>
                            <QRCodeSVG value={harmonyAddress} size="250" style={{ margin: "5px", backgroundColor: "white" }} />
                        </div>
                    </>
                ) : (
                    <>

                        <div style={{ textAlign: "left", padding: "5px" }}>Ethereum Address:</div>
                        <div style={{ textAlign: "left", padding: "5px", fontSize: "small", color: "#00AEE9" }}>{ethereumAddress}<FontAwesomeIcon size="sm" style={{ cursor: "pointer", color: "##FF6F00", float: "right" }} icon={solid('copy')} onClick={() => copyToClipBoard(ethereumAddress)} data-tip='Copied!' data-event='click focus' /></div>
                        <br />
                        <div style={{ padding: "5px", backgroundColor: "white" }}>
                            <QRCodeSVG value={ethereumAddress} size="250" style={{ margin: "5px", backgroundColor: "white" }} />
                        </div>
                    </>
                )
                }
                < ReactTooltip globalEventOff='click' />
            </div>



            <div style={{ padding: "10px", fontSize: "small", textAlign: "left", backgroundColor: "black", border: "2px solid black", borderBottomLeftRadius: "20px", borderBottomRightRadius: "20px" }}>

            </div>

        </>
    )
}

export default Account