import React from 'react'
import AccountStats from './AccountStats'
import Rewards from './Rewards'
import Stake from './Stake'
import { useState } from "react";
import { claimRewards, compound } from "../utils/staking";
import Overlay from './Overlay';
import { useSelector, useDispatch } from 'react-redux';
import { openStatusDialog, updateStatusDialog } from '../state/statusSlice';
import { formatONE } from '../utils/numberFormat';

const Body = ({ ReactGA, web3, showAccount, setShowAccount }) => {
    const { ethereumAddress, unclaimedRewards } = useSelector((state) => state.wallet);
    const validatorAddress = useSelector((state) => state.validator.address);
    const dispatch = useDispatch();
    const { dialogOpen } = useSelector((state) => state.status);
    const [showDelegate, setShowDelegate] = useState(false);
    const [showWithdraw, setShowWithdraw] = useState(false);
    const [showBalance, setShowBalance] = useState(false);
    
    
    function updateStatus(_statusParams, receipt) {
        dispatch(updateStatusDialog(_statusParams, receipt, web3));
        console.log(receipt)
    }

    const collectOnClick = () => {
        let _statusParams = {
            ready: true,
            headerText: 'Claiming rewards..',
            bodyText: 'Please sign staking transaction.',
            status: 'pending',
            statusMessage: '',
            tasks: [{
                id: 0,
                description: 'Claiming ' + formatONE(unclaimedRewards, 4) + ' ONE.',
                status: 'pending'
            }]
        };
        dispatch(openStatusDialog(_statusParams));

        claimRewards(web3, ethereumAddress, updateStatus, _statusParams);
        ReactGA.event({
            category: 'User',
            action: 'ClaimRewards',
          });
    }

    const compoundOnClick = () => {
        let _amount = unclaimedRewards;
        let _statusParams = {
            ready: true,
            headerText: 'Compounding rewards..',
            bodyText: 'Please sign staking transactions.',
            tasks: [{
                id: 0,
                description: 'Claiming ' + formatONE(unclaimedRewards, 7) + ' ONE.',
                status: 'pending'
            },
            {
                id: 1,
                description: 'Staking ' + formatONE(unclaimedRewards, 7) + ' ONE.',
                status: 'pending'
            }]
        };
        dispatch(openStatusDialog(_statusParams));

        compound(web3, ethereumAddress, validatorAddress, _amount, _statusParams, updateStatus);

        ReactGA.event({
            category: 'User',
            action: 'CompoundRewards'
          });
    }

    function closeOverlay() {
        setShowWithdraw(false);
        setShowDelegate(false);
        setShowBalance(false);
        setShowAccount(false);
    }


    return (
        <>
            <Overlay ReactGA={ReactGA} isOpen={showWithdraw || showDelegate || dialogOpen || showBalance || showAccount} close={closeOverlay} web3={web3} isDeposit={showDelegate} isWithdraw={showWithdraw} isBalance={showBalance} isAccount={showAccount} />
            <div className="row">
                <AccountStats showBalance={() => setShowBalance(true)} />
                <Stake ReactGA={ReactGA} delegate={() => setShowDelegate(true)} undelegate={() => setShowWithdraw(true)} />
                <Rewards claim={() => collectOnClick()} compound={() => compoundOnClick()} />
            </div>
        </>
    )
}

export default Body
