import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { formatONE, toONE } from '../../utils/numberFormat';
import { useSelector } from 'react-redux';

const Balance = ({ close, web3 }) => {
    const { totalStake, balance, pendingUndelegations, totalUndelegating, unclaimedRewards, epoch, symbol } = useSelector((state) => state.wallet);
    //console.log(pendingUndelegations);


    function getWithdrawalTime (undelegateEpoch) { 
        const remainingEpocs =  (undelegateEpoch + 7) - epoch;
        const blocksPerEpoch = 32768;
        const remainingTimeMiliseconds = remainingEpocs * blocksPerEpoch * 2 * 1000;
        let returnDate = new Date();
        const future = returnDate.getTime() + remainingTimeMiliseconds;
        //console.log(remainingTimeMiliseconds)
        returnDate = new Date(future);
        //console.log(returnDate) 
        return returnDate.getFullYear() + '-' + (returnDate.getMonth() + 1) + '-' + returnDate.getDate();
    }

    return (
        <>
            <div className='overlay-header' style={{ color: "white", backgroundColor: "black", border: "2px solid black", borderTopLeftRadius: "20px", borderTopRightRadius: "20px" }}>
                <div style={{ width: "23%", display: "inline-block" }}>

                </div>
                <div style={{ textAlign: "center", width: "50%", display: "inline-block", color: "#00AEE9" }}>
                    <h3>Account Balance</h3>
                </div>
                <div style={{ width: "23%", display: "inline-block", padding: "5px" }}>
                    <FontAwesomeIcon size="xl" style={{ cursor: "pointer", color: "##FF6F00", float: "right" }} icon={solid('circle-xmark')} onClick={() => close()} />
                </div>
            </div>
            <div style={{ content: "", display: "table", clear: "both" }}></div>

            <div id='overlay-content' style={{ textAlign: "center", border: "3px solid black", backgroundColor: "rgb(57, 54, 54)", color: "white", display: "grid", gridTemplateColumns: "auto auto" }}>
                <div style={{ textAlign: "left", padding: "5px" }}>Wallet Balance:</div><div style={{ textAlign: "right", padding: "5px" }}>{formatONE(balance)} {symbol}</div>
                <div style={{ textAlign: "left", padding: "5px" }}>Total Staked:</div><div style={{ textAlign: "right", padding: "5px" }}>{formatONE(totalStake)} {symbol}</div>
                <div style={{ textAlign: "left", padding: "5px" }}>Unclaimed Rewards:</div><div style={{ textAlign: "right", padding: "5px" }}>{formatONE(unclaimedRewards)} {symbol}</div>
                <div style={{ textAlign: "left", padding: "5px" }}>Withdrawing:</div><div style={{ textAlign: "right", padding: "5px" }}>{formatONE(totalUndelegating)} {symbol}</div>        
                {
                    pendingUndelegations.map((undelegation) => <><div key={undelegation.toString()} style={{ textAlign: "left", padding: "5px", paddingLeft: "10px", fontSize: "small" }}>{formatONE(toONE(undelegation.Amount))} {symbol}</div><div style={{ textAlign: "right", padding: "5px", paddingRight: "10px", fontSize: "small" }}>Est. Return: {getWithdrawalTime(undelegation.Epoch)}</div></>)
                }
                <div style={{ textAlign: "left", padding: "5px" }}>Total:</div><div style={{ textAlign: "right", padding: "5px" }}>{formatONE(Number(balance) + Number(totalStake) + Number(unclaimedRewards) + Number(totalUndelegating))} {symbol}</div>
            </div>



            <div style={{ padding: "10px", fontSize: "small", textAlign: "left", backgroundColor: "black", border: "2px solid black", borderBottomLeftRadius: "20px", borderBottomRightRadius: "20px" }}>

            </div>
        </>
    )
}

export default Balance