import { createSlice } from "@reduxjs/toolkit";
import { addTxFromReceipt } from "./transactionsSlice";
import { getDelegatorData } from "./walletSlice";

const initialState = {
    dialogOpen: false,
    status: '', // pending, complete, failed
    statusMessage: '',
    mainText: '',
    bodyText: '',
    tasks: [],

}

const statusSlice = createSlice({
    name: 'status',
    initialState,
    reducers: {
        setDialogOpen: (state, action) => {
            state.dialogOpen = action.payload;
        },
        setStatus: (state, action) => {
            state.status = action.payload;
        },
        setStatusMessage: (state, action) => {
            state.statusMessage = action.payload;
        },
        setMainText: (state, action) => {
            state.mainText = action.payload;
        },
        setBodyText: (state, action) => {
            state.bodyText = action.payload;
        },
        setTasks: (state, action) => {
            state.tasks = action.payload
        },
        resetDialog: (state) => {
            state = initialState;
        }
    }
});

export default statusSlice.reducer

export const { setDialogOpen, setMainText, setTasks, resetDialog, setStatus, setStatusMessage, setBodyText } = statusSlice.actions

export const openStatusDialog = (_params) => (dispatch) => {
    dispatch(resetDialog());
    dispatch(setDialogOpen(true));
    dispatch(setMainText(_params.headerText));
    dispatch(setBodyText(_params.bodyText));
    dispatch(setStatus('pending'));
    dispatch(setStatusMessage(''));
    dispatch(setTasks(_params.tasks));

}

export const updateStatusDialog = (_params, _receipt, _web3) => (dispatch) => {
    dispatch(setStatus(_params.status));
    dispatch(setStatusMessage(_params.statusMessage));
    dispatch(setTasks(_params.tasks));
    if (_receipt) {
        console.log('Update status')
        console.log(_receipt)
        dispatch(addTxFromReceipt(_receipt, _web3));
        dispatch(getDelegatorData(_receipt.from, _web3));
    }

}



