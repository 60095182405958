import React from 'react'
import { switchChain } from '../utils/metamask'
import { setChain } from '../state/walletSlice';
import { useDispatch } from 'react-redux';

const SwitchNetwork = ({ web3 }) => {
    const harmonyChainId = '0x63564c40';
    //const findoraChainId = '0x868';
    const dispatch = useDispatch();

    const errorMessage = (e) => {
        alert(e);
    }

    const dispatchNewChainId = (chainId) => {
        dispatch(setChain(chainId))
    }

    return (
        <div className='box'>
            <h1 style={{ color: "red" }}>Unsupported Network</h1>
            <button className='btn' onClick={() => {
                switchChain(harmonyChainId, web3, errorMessage, dispatchNewChainId);
                //dispatchNewChainId(harmonyChainId);
            }}>Switch to Harmony</button>
            {/*
            <button className='btn' onClick={() => switchChain(findoraChainId, web3, errorMessage)}>Switch to Findora</button>
            */}
        </div>
    )
}

export default SwitchNetwork