import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { downloadTxs } from '../utils/transactionData';
import { useSelector } from 'react-redux';
import { formatONE } from '../utils/numberFormat';

const Transactions = ({ formatedTxs }) => {
    const { ethereumAddress } = useSelector((state) => state.wallet);
    const { txLoaded } = useSelector((state) => state.transactions);
    //const txs = [].concat(formatedTxs)
    const txs = formatedTxs.filter((tx, index, arr) => {
        return arr.map(mapObj => mapObj.hash).indexOf(tx.hash) === index;
    });

    txs.sort((a, b) => {
        return b.timestamp - a.timestamp;
    })

    const downloadTransactions = () => {
        downloadTxs(txs, ethereumAddress)
    }

    return (
        <div className='row'>

            <div className='tx-column'>
                <div className='tx-box'>
                    <div className='click-me' onClick={() => downloadTransactions()}>
                        <FontAwesomeIcon size={"1x"} icon={solid('file-csv')} />
                    </div>
                    <table className='table-scroll'>
                        <thead>
                            <tr>
                                <th className='col-1'>Date</th>
                                <th className='col-2'>Function</th>
                                <th className='col-3'>Amount</th>
                                {/* <th className='col-4'>Value</th> */}
                                <th className='col-5'>Gas Fee</th>
                            </tr>
                        </thead>
                        <tbody className="body-half-screen">
                            {(txs.length === 0 && !txLoaded) && <div><FontAwesomeIcon style={{ color: "#00AEE9" }} spin={true} icon={solid('spinner')} /> Loading Transactions...</div>}
                            {(txs.length === 0 && txLoaded) && <div>No Transactions.</div>}
                            {                                
                                txs.map((tx) => {
                                    return (
                                        < tr key={tx.hash} >
                                            <td className='col-1'>{tx.dateTime}</td>
                                            <td className='col-2'>{tx.function}</td>
                                            <td className='col-3'>{formatONE(tx.amount)} ONE</td>
                                            {/*  <td className='col-4'>{tx.value}</td> */}
                                            <td className='col-5'>{tx.gasFee} ONE</td>
                                        </tr>
                                    )

                                })
                            }
                        </tbody>
                    </table>
                </div >
            </div>
        </div >
    )
}

export default Transactions