import moment from 'moment';
//import { useSelector, useDispatch } from 'react-redux';
import { abi } from "../abi/staking-abi";
import { uniswapAbi } from '../abi/uniswap-abi';
import { erc20Abi } from '../abi/erc20-abi';
import { uniSwapV2Pair } from '../abi/uniswapPair-abi';
import { comptrollerAbi } from '../abi/comptroller-abi';
import { unitrollerAbi } from '../abi/unitroller-abi';
import { compAbi } from '../abi/comp-abi';
import { cTokenAbi } from '../abi/ctoken-abi';
import { erc721Abi } from '../abi/erc721-abi';
import { HarmonyAddress } from "@harmony-js/crypto";
import abiDecoder from 'abi-decoder';
import { tokenNames } from './harmony';
import Web3 from 'web3';

export function downloadTxs(txs, addr) {
    let csv = 'Date,Sent Amount,Sent Currency,Received Amount,Received Currency,Fee Amount,Fee Currency,Net Worth Amount,Net Worth Currency,Label,Description,TxHash\n';


    txs.forEach(tx => {
        let feeCurrency = 'ONE';
        let sentAmount = '';
        let sentCurrency = '';
        let receivedAmount = '';
        let receivedCurrency = '';
        let label = '';
        let description = '';

        switch (tx.function) {
            case 'Deposit': {
                sentAmount = tx.amount;
                sentCurrency = 'ONE';
                label = 'stake';
                break;
            }
            case 'Withdraw': {
                receivedAmount = tx.amount;
                receivedCurrency = 'ONE';
                label = 'unstake';
                break;
            }
            case 'Claim Reward': {
                receivedAmount = tx.amount;
                receivedCurrency = 'ONE';
                label = 'reward';
                break;
            }
            default: {
                // to-do?
            }
        }
        let line = tx.dateTime + ',' + sentAmount + ',' + sentCurrency + ',' + receivedAmount + ',' + receivedCurrency + ',' + tx.gasFee + ',' + feeCurrency + ',' + ',,' + label + ',' + description + ',' + tx.hash;
        csv += line + '\n';
    });

    var hiddenElement = document.createElement('a');
    hiddenElement.href = 'data:text/csv;charset=utf-8,' + encodeURI(csv);
    hiddenElement.target = '_blank';

    //provide the name for the CSV file to be downloaded  
    hiddenElement.download = addr + ' Transactions.csv';
    hiddenElement.click();
}

export async function getTransactionData(addr, transactions, txReceipts) {

    if (!addr)
        return;

    const stakingContract = new HarmonyAddress('one1qqqqqqqqqqqqqqqqqqqqqqqqqqqqqq8uuuycsy');

    abiDecoder.addABI(abi);
    abiDecoder.addABI(uniswapAbi)
    abiDecoder.addABI(erc20Abi)
    abiDecoder.addABI(uniSwapV2Pair)
    abiDecoder.addABI(comptrollerAbi)
    abiDecoder.addABI(unitrollerAbi)
    abiDecoder.addABI(compAbi)
    abiDecoder.addABI(cTokenAbi)
    abiDecoder.addABI(erc721Abi)
    const harmonyAddr = new HarmonyAddress(addr);

    let csv = 'Date,Sent Amount,Sent Currency,Received Amount,Received Currency,Fee Amount,Fee Currency,Net Worth Amount,Net Worth Currency,Label,Description,TxHash\n';

    transactions.forEach(transaction => {

        const timestamp = transaction.timestamp;
        const transDate = moment(timestamp * 1000).format('YYYY-MM-DD HH:mm:ss');
        const toAddr = transaction.to;
        const fromAddr = transaction.from;
        const value = transaction.value / 1e18;
        const currency = 'ONE';
        const hash = transaction.hash;
        const rcpt = txReceipts.filter(receipt => receipt.blockHash === transaction.blockHash)

        let fee = '';
        let feeCurrency = '';
        let receivedAmount = '';
        let receivedCurrency = '';
        let sentAmount = '';
        let sentCurrency = '';
        let label = '';
        let description = '';

        if (rcpt[0].status === 1) {
            if (toAddr === harmonyAddr.bech32) {
                //console.log('received ', value)
                receivedAmount = value;
                receivedCurrency = currency;
            }
            if (fromAddr === harmonyAddr.bech32) {
                //console.log('sent ', value)
                sentAmount = value;
                sentCurrency = currency;
                fee = transaction.gas / 10000000; // only add fee if we're doing the sending
                feeCurrency = currency;
            }
            if (transaction.input) {
                const decodedInput = abiDecoder.decodeMethod(transaction.input);
                if (decodedInput)
                    description = decodedInput.name
                //console.log(decodedInput)
                if (decodedInput)
                    switch (decodedInput.name) {
                        case 'mint': {
                            console.log(decodedInput);
                            console.log(rcpt)
                            getLogs(rcpt)
                            break;
                        }
                        case 'redeem': {
                            if (toAddr === 'one1eutsnttk57w45cpppu37s88zgcz592pkn604v7')
                                console.log('TRANQ Redeem')
                            console.log(decodedInput);
                            console.log(rcpt)
                            getLogs(rcpt)
                            break;
                        }
                        case 'enterMarkets': {
                            console.log(decodedInput);
                            console.log(rcpt)
                            getLogs(rcpt)
                            break;
                        }
                        case 'transferFrom': {
                            console.log(decodedInput);
                            console.log(rcpt)
                            //getLogs(rcpt)
                            break;
                        }
                        case 'Transfer': {
                            description = 'Transfer';
                            console.log(decodedInput);
                            console.log(rcpt)
                            let tokenName = tokenNames[toAddr]
                            if (!tokenName) {
                                console.log('Token: ', toAddr)
                                tokenName = toAddr;
                            }
                            getLogs(rcpt)
                            break;
                        }
                        case 'approve': {
                            let tokenName = tokenNames[toAddr]
                            let approvedAmount = decodedInput.params[1].value;
                            if (approvedAmount === '115792089237316195423570985008687907853269984665640564039457584007913129639935')
                                approvedAmount = 'Unlimited'
                            else
                                approvedAmount = approvedAmount / 1e18;
                            if (!tokenName) {
                                console.log('Token: ', toAddr)
                                tokenName = toAddr;
                            }
                            description = 'Approve ' + approvedAmount + ' ' + tokenName
                            break;
                        }
                        case 'swapExactTokensForETH': {
                            label = 'swap';
                            description = decodedInput.name;

                            receivedCurrency = currency;
                            sentAmount = Web3.utils.fromWei(decodedInput.params[0].value, "ether");

                            sentCurrency = tokenNames[new HarmonyAddress(decodedInput.params[2].value[0]).bech32]
                            receivedAmount = getSwapOut(rcpt);

                            break;
                        }
                        case 'swapExactETHForTokens': {
                            label = 'swap';
                            description = decodedInput.name;

                            sentCurrency = currency;
                            sentAmount = Web3.utils.fromWei(decodedInput.params[0].value, "ether");

                            receivedCurrency = tokenNames[new HarmonyAddress(decodedInput.params[1].value[1]).bech32]
                            receivedAmount = getSwapOut(rcpt);
                            break;
                        }
                        case 'swapExactTokensForTokens': {
                            label = 'swap';
                            description = decodedInput.name;

                            sentAmount = Web3.utils.fromWei(decodedInput.params[0].value, "ether");
                            sentCurrency = tokenNames[new HarmonyAddress(decodedInput.params[2].value[0]).bech32]
                            receivedCurrency = tokenNames[new HarmonyAddress(decodedInput.params[2].value[1]).bech32]
                            receivedAmount = getSwapOut(rcpt);
                            break;
                        }
                        default: {
                            //console.log(decodedInput.name);
                            console.log(decodedInput);
                            //console.log(rcpt)
                            //getLogs(rcpt)
                        }
                    }
            }

            if (toAddr === stakingContract.bech32) {
                //console.log(transaction)
                const decodedInput = abiDecoder.decodeMethod(transaction.input);
                //console.log(decodedInput);
                if (decodedInput.name)
                    description = 'StakingPrecompile ' + decodedInput.name;

                switch (decodedInput.name) {
                    case 'Delegate': {
                        label = 'stake';
                        //sentAmount = decodedInput.params[2].value / 1e18;
                        sentAmount = Web3.utils.fromWei(decodedInput.params[2].value, "ether");
                        sentCurrency = currency;
                        break;
                    }
                    case 'Undelegate': {
                        label = 'unstake';
                        receivedAmount = Web3.utils.fromWei(decodedInput.params[2].value, "ether");
                        receivedCurrency = currency;
                        break;
                    }
                    case 'CollectRewards': {
                        label = 'reward';

                        receivedAmount = Web3.utils.fromWei(rcpt[0].logs[0].data, "ether");
                        receivedCurrency = currency;

                        //console.log('ClaimReward: ' + receivedAmount + ' ' + receivedCurrency)

                        break;
                    }
                }
            }
        }

        if (sentAmount === 0) {
            sentAmount = '';
            sentCurrency = '';
        }
        if (receivedAmount === 0) {
            receivedAmount = '';
            receivedCurrency = '';
        }

        let line = transDate + ',' + sentAmount + ',' + sentCurrency + ',' + receivedAmount + ',' + receivedCurrency + ',' + fee + ',' + feeCurrency + ',' + ',,' + label + ',' + description + ',' + hash;

        csv += line + '\n';
    });

    var hiddenElement = document.createElement('a');
    hiddenElement.href = 'data:text/csv;charset=utf-8,' + encodeURI(csv);
    hiddenElement.target = '_blank';

    //provide the name for the CSV file to be downloaded  
    hiddenElement.download = addr + ' Transactions.csv';
    hiddenElement.click();
}

function getSwapOut(receipt) {
    let logs = [];
    for (let i = 0; i < receipt.length; i++) {
        const l = abiDecoder.decodeLogs(receipt[i].logs)
        logs = logs.concat(l)
    }

    const swapLog = logs.filter(log => log.name === 'Swap');
    const amountOutLog = swapLog[0].events.filter(log => ((log.name === 'amount0Out' || log.name === 'amount1Out') && Number(log.value) > 0))
    return Web3.utils.fromWei(amountOutLog[0].value)
}

function getLogs(receipt) {
    let logs = [];
    for (let i = 0; i < receipt.length; i++) {
        const l = abiDecoder.decodeLogs(receipt[i].logs)
        logs = logs.concat(l)
    }
    console.log(logs)
}