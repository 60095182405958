import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    gasPrice: 0,
    gasLimit: 45000,
    maxGas: 0,
};

const harmonySlice = createSlice({
    name: 'harmony',
    initialState,
    reducers: {
        setGasPrice: (state, action) => {
            state.gasPrice = action.payload;
        },
        setGasLimit: (state, action) => {
            state.gasLimit = action.payload;
        },
        setMaxGas: (state, action) => {
            state.maxGas = action.payload;
        }
    }
});

export const { setGasPrice, setGasLimit, setMaxGas } = harmonySlice.actions;

export default harmonySlice.reducer