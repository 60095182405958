import { React, useEffect } from 'react';
import Deposit from './overlay/Deposit';
import Withdraw from './overlay/Withdraw';
import { useSelector } from 'react-redux';
import StatusDialog from './overlay/StatusDialog';
import Balance from './overlay/Balance';
import Account from './overlay/Account';

const Overlay = ({ ReactGA, isOpen, close, isDeposit, web3, isWithdraw, isBalance, isAccount }) => {
    const { dialogOpen } = useSelector((state) => state.status);

    useEffect(() => {
        if (isOpen)
            on()
        else
            off();
    }, [isOpen]);


    function on() {
        document.getElementById("overlay").style.display = "block";
        
    }

    function off() {
        document.getElementById("overlay").style.display = "none";
    }

    return (
        <div id='overlay'>
            <div style={{ height: "20%" }}></div>
            <div className='overlay-box'>
                {dialogOpen &&
                    <StatusDialog close={() => close()} />
                }
                {(!dialogOpen && isDeposit) && <Deposit ReactGA={ReactGA} web3={web3} close={() => close()} />}
                {(!dialogOpen && isWithdraw) && <Withdraw ReactGA={ReactGA} web3={web3} close={() => close()} />}
                {(!dialogOpen && isBalance) && <Balance close={() => close()}  />}
                {(!dialogOpen && isAccount) && <Account close={() => close()}  />}
            </div>
            <div style={{ height: "30%" }}></div>
        </div>
    )
}

export default Overlay