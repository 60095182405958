import { React, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { getExchangeRate } from "../state/currencySlice";
import { useCookies } from 'react-cookie';

const CurrencySelect = () => {
    const [cookies, setCookie] = useCookies(['currency']);
    const { currency, supportedCurrencies } = useSelector((state) => state.currency);
    const dispatch = useDispatch();

    useEffect(() => {
        if (supportedCurrencies.length < 1)
            return;

        let select = document.getElementById("currency");
        // remove any existing options
        while (select.hasChildNodes()) {
            select.removeChild(select.firstChild);
        }
        // add options from currencies array
        let currencies = supportedCurrencies || [];
        currencies.forEach((currentValue, index, arr) => {
            let option = document.createElement("OPTION");
            option.value = currentValue;
            option.text = currentValue.toUpperCase();
            if (currency === currentValue)
                option.selected = true;
            select.add(option);

            //return 0;
        })

    }, [supportedCurrencies, currency, dispatch])

    return (

        <select name="currency" id="currency" style={
            {
                color: "#00AEE9",
                backgroundColor: "rgb(57, 54, 54)",
                border: "none",
                cursor: "pointer"
            }
        }
            onChange={(e) => {
                dispatch(getExchangeRate(e.target.value));
                if (cookies.currency !== e.target.value)
                    setCookie('currency', e.target.value, { path: '/' })
            }
            }
        />

    )
}

export default CurrencySelect