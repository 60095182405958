import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { formatONE } from '../../utils/numberFormat';
import { useSelector, useDispatch } from 'react-redux';
import { undelegate } from '../../utils/staking';
import { openStatusDialog, updateStatusDialog } from '../../state/statusSlice';
import ValidatorSelect from './ValidatorSelect';

const Withdraw = ({ ReactGA, close, web3 }) => {
    const dispatch = useDispatch();
    const { delegations, ethereumAddress } = useSelector((state) => state.wallet);
    const { maxGas, gasPrice } = useSelector((state) => state.harmony);
    const [withdrawable, setWithdrawable] = useState(() => 0);
    const [withdrawValidator, setWithdrawValidator] = useState(null);
    const [amount, setAmount] = useState(() => 0);

    useEffect(() => {
        if (!withdrawValidator) {
            setWithdrawValidator(delegations[0].validatorAddress);
            setWithdrawable(delegations[0].amount);
        }
    }, [dispatch, withdrawValidator, delegations])

    //document.getElementById("amount").focus();

    function balanceClick() {
        setAmount(parseFloat(Math.floor(withdrawable)) || 0)
    }

    function updateStatus(_statusParams, _receipt) {
        dispatch(updateStatusDialog(_statusParams, _receipt, web3));
    }

    function withdraw() {
        console.log('Withdraw: ' + amount);

        let _statusParams = {
            ready: true,
            headerText: 'Withdrawing ONE...',
            bodyText: 'Please sign staking transaction.',
            status: 'pending',
            statusMessage: '',
            tasks: [{
                id: 0,
                description: 'Withdrawing ' + amount + ' ONE.',
                status: 'pending'
            }]
        };
        dispatch(openStatusDialog(_statusParams));

        undelegate(web3, ethereumAddress, amount, withdrawValidator, _statusParams, updateStatus, gasPrice);

        ReactGA.event({
            category: 'User',
            action: 'Withdraw'
          });
    }

    return (
        <>
            <div className='overlay-header' style={{ color: "white", backgroundColor: "black", border: "2px solid black", borderTopLeftRadius: "20px", borderTopRightRadius: "20px" }}>
                <div style={{ width: "23%", display: "inline-block" }}>

                </div>
                <div style={{ textAlign: "center", width: "50%", display: "inline-block", color: "#00AEE9" }}>
                    <h3>Withdraw ONE</h3>
                </div>
                <div style={{ width: "23%", display: "inline-block", padding: "5px" }}>
                    <FontAwesomeIcon size="xl" style={{ cursor: "pointer", color: "##FF6F00", float: "right" }} icon={solid('circle-xmark')} onClick={() => close()} />
                </div>
            </div>
            <div style={{ content: "", display: "table", clear: "both" }}></div>

            <div id='overlay-content' style={{ textAlign: "center", border: "3px solid black", backgroundColor: "rgb(57, 54, 54)", color: "white" }}>


                <input id="amount" type="number" value={amount} onChange={(e) => setAmount(e.target.value)} style={{ width: "90%", color: "white", backgroundColor: "rgb(57, 54, 54)", margin: "10px", fontSize: "large", fontWeight: "bold", border: "solid black", borderRadius: "5px", padding: "5px", textAlign: "center" }} />
               
                    <ValidatorSelect
                        setValidator={(addr) => setWithdrawValidator(addr)}
                        selectedValidator={withdrawValidator}
                        setWithdrawable={(amount) => setWithdrawable(amount)}
                    />
                
                <div onClick={() => balanceClick()} style={{ margin: "10px", padding: "5px", fontSize: "small", cursor: "pointer", width: "90%" }}>
                    <div style={{ display: "inline-block", width: "30%", textAlign: "left" }}>Available to Withdraw:</div>
                    <div style={{ display: "inline-block", width: "70%", textAlign: "right" }}>{formatONE(withdrawable)} ONE</div>
                </div>

                <div style={{ margin: "10px", padding: "5px", fontSize: "small", width: "90%" }}>
                    <div style={{ display: "inline-block", width: "30%", textAlign: "left" }}>Network Fee:</div>
                    <div style={{ display: "inline-block", width: "70%", textAlign: "right" }}>{formatONE(maxGas, 5)} ONE</div>
                </div>
                <div style={{ margin: "10px", padding: "5px" }}>
                    <button className="btn" onClick={() => withdraw()} style={{ width: "100%", height: "50px", cursor: "pointer", margin: "auto" }}><FontAwesomeIcon icon={solid('circle-minus')} /> Withdraw</button>
                </div>
            </div>
            <div style={{ padding: "10px", fontSize: "small", textAlign: "left", backgroundColor: "black", border: "2px solid black", borderBottomLeftRadius: "20px", borderBottomRightRadius: "20px" }}>
                <p style={{ color: "white" }}>DK Validator is a non-custodial staking service provider. All cryptocurrency delegated to DK Validator remain under the sole control of the wallet holder.</p>
                <p style={{ color: "white" }}>Rewards are distributed by the Harmony blockchain network every sixty seconds and are subject to a 5% operator fee.</p>
                <p style={{ color: "red" }}><b>Note:</b> Withdrawing deposited ONE is subject to an unlocking period of seven epochs, which is approximately five days depending on block time.</p>
            </div>
        </>
    )
}

export default Withdraw