import { createSlice } from "@reduxjs/toolkit";
import { HarmonyAddress } from "@harmony-js/crypto";
import { ethers } from 'ethers';
import { Units, fromWei } from "@harmony-js/utils"
import { BN } from "@harmony-js/crypto";
import { toONE } from "../utils/numberFormat";
import { getDelegationsByDelegator, getEpoch as getHmyEpoch, getValidatorInformation } from "../utils/harmony";
import { getValidatorData, getFindoraValidatorData } from "./validatorSlice";
import { getExchangeRate, setExchangeRate } from "./currencySlice";

const initialState = {
    isConnected: false,
    isHarmony: false,
    isFindora: false,
    ethereumAddress: '',
    harmonyAddress: null,
    ensName: '',
    chainId: 0,
    balance: 0,
    unclaimedRewards: 0,
    totalStake: 0,
    totalUndelegating: 0,
    delegations: [],
    pendingUndelegations: [], // {epoch: , validator: , amount: }
    availableToStake: 0,
    epoch: 0,
    stakablePending: 0,
    symbol: ''
}

const walletSlice = createSlice({
    name: 'wallet',
    initialState,
    reducers: {
        setIsConnected: (state, action) => {
            state.isConnected = action.payload
        },
        setIsHarmony: (state, action) => {
            state.isHarmony = action.payload
        },
        setIsFindora: (state, action) => {
            state.isFindora = action.payload
        },
        setSymbol: (state, action) => {
            state.symbol = action.payload;
        },
        setEthereumAddress: (state, action) => {
            state.ethereumAddress = action.payload;
        },
        setHarmonyAddress: (state, action) => {
            state.harmonyAddress = action.payload;
        },
        setEnsName: (state, action) => {
            state.ensName = action.payload;
        },
        setChainId: (state, action) => {
            state.chainId = action.payload;
        },
        setWeb3: (state, action) => {
            state.web3 = action.payload;
        },
        setBalance: (state, action) => {
            state.balance = action.payload;
        },
        setUnclaimedRewards: (state, action) => {
            state.unclaimedRewards = action.payload;
        },
        setDelegations: (state, action) => {
            state.delegations = action.payload;
        },
        setPendingUndelegations: (state, action) => {
            state.pendingUndelegations = action.payload;
        },
        setTotalStake: (state, action) => {
            state.totalStake = action.payload;
        },
        setTotalUndelegating: (state, action) => {
            state.totalUndelegating = action.payload;
        },
        setAvailableToStake: (state, action) => {
            state.availableToStake = action.payload;
        },
        reset: (state) => {
            state = initialState;
        },
        setEpoch: (state, action) => {
            state.epoch = action.payload;
        },
        setStakablePending: (state, action) => {
            state.stakablePending = action.payload;
        }
    }
});

export const { setIsConnected, setIsHarmony, setIsFindora, setSymbol, setEnsName, setChainId, setEpoch, setEthereumAddress, setHarmonyAddress, setWeb3, setBalance, reset, setDelegations, setPendingUndelegations, setAvailableToStake, setUnclaimedRewards, setTotalStake, setTotalUndelegating, setStakablePending } = walletSlice.actions

export const setChain = (chainId) => (dispatch, getState) => {
    const { ethereumAddress } = getState().wallet;

    dispatch(setChainId(chainId));
    dispatch(setBalance(0));

    let harmony = false, findora = false;

    switch (Number(chainId)) {
        case 0x63564c40: {
            harmony = true;
            dispatch(setSymbol('ONE'));
            dispatch(getEpoch());
            break;
        }
        case 2152: {
            findora = true;
            dispatch(setSymbol('FRA'));
            break;
        }
        default: {
            findora = false;
            harmony = false;
            dispatch(setSymbol(''));
        }
    }

    dispatch(setIsHarmony(harmony));
    dispatch(setIsFindora(findora));
    dispatch(setExchangeRate());
    dispatch(getDelegatorData(ethereumAddress, null))
    if (findora)
        dispatch(getFindoraValidatorData(ethereumAddress));
    else
        dispatch(getValidatorData(ethereumAddress));
}

export const setAddress = (address) => (dispatch) => {
    if (!address || address === '')
        return;

    const hmyAddr = new HarmonyAddress(address);
    dispatch(setEthereumAddress(String(hmyAddr.basicHex)));
    dispatch(setHarmonyAddress(hmyAddr.bech32));
    dispatch(setENSAddress(address));
    dispatch(getDelegatorData(address));
}

export const setENSAddress = (address) => async (dispatch) => {
    if (!address)
        return;

    let ethersProvider = new ethers.providers.JsonRpcProvider('https://mainnet.infura.io/v3/b32eeee988dc43b5baae5467eb159759');
    const _ensName = await ethersProvider.lookupAddress(address);

    dispatch(setEnsName(_ensName));
}

export const getDelegatorData = (address, web3) => async (dispatch, getState) => {
    if (!address)
        return;

    const { chainId, epoch } = getState().wallet;
    dispatch(getDelegatorBalance(address, web3));

    if (Number(chainId) !== Number(0x63564c40)) {
        dispatch(setPendingUndelegations([]));

        dispatch(setUnclaimedRewards(0));
        dispatch(setTotalStake(0));
        dispatch(setTotalUndelegating(0));
        dispatch(setStakablePending(0));
        dispatch(setDelegations([]));
        dispatch(getDelegateNames([]));

        return;
    }

    const responseJson = await getDelegationsByDelegator(address);

    if (responseJson.error)
        return;


    const delArray = responseJson.result;

    let rewards = new BN('0'), delegations = 0, totalUndelegations = 0, stakable = 0, delegationArray = [], undelegationArray = [];

    for (let i = 0; i < delArray.length; i++) {
        rewards = rewards.add(new BN(String(delArray[i].reward)));

        if (delArray[i].amount > 0) {
            delegations += toONE(delArray[i].amount);
            const delegation = {
                validatorAddress: delArray[i].validator_address,
                amount: toONE(delArray[i].amount),
            }
            delegationArray.push(delegation);
        }

        const undelArray = delArray[i].Undelegations;

        for (let x = 0; x < undelArray.length; x++) {
            undelegationArray.push({
                Epoch: undelArray[x].Epoch,
                Amount: undelArray[x].Amount
            })
            totalUndelegations += toONE(undelArray[x].Amount);
            if (Number(undelArray[x].Epoch) < Number(epoch))
                stakable += toONE(undelArray[x].Amount);

        }
    }
    dispatch(setPendingUndelegations(undelegationArray));

    dispatch(setUnclaimedRewards(Number(fromWei(rewards, Units.one))));
    dispatch(setTotalStake(delegations));
    dispatch(setTotalUndelegating(totalUndelegations));
    dispatch(setStakablePending(stakable));
    dispatch(setDelegations(delegationArray));
    dispatch(getDelegateNames(delegationArray));
}

export const getDelegateNames = (delegationArray) => async (dispatch) => {
    if (!delegationArray)
        return;

    let delegations = JSON.parse(JSON.stringify(delegationArray));

    for (let i = 0; i < delegations.length; i++) {
        const res = await getValidatorInformation(delegations[i].validatorAddress);
        if (!res.error) {
            const validator = res.result;
            delegations[i].validatorName = validator.validator.name;
        }

    }

    dispatch(setDelegations(delegations));
}

export const getDelegatorBalance = (address, web3) => async (dispatch) => {
    dispatch(getExchangeRate());

    if (!web3)
        return;
    
    console.log('getting balance for ', address)

    const a = await web3.eth.getBalance(address);
    if (a) {
        let b = web3.utils.fromWei(String(a), "ether");
        dispatch(setBalance(Number(b)));
    }

}

export const getEpoch = () => async (dispatch) => {
    const res = await getHmyEpoch();
    dispatch(setEpoch(res.result));
}

export default walletSlice.reducer

