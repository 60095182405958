import BigNumber from "bignumber.js";

export function getLang() {
    if (navigator.languages !== undefined)
        return navigator.languages[0];
    return navigator.language;
}

export function toONE(amount = 0) {
    return BigNumber(amount).div(1e18).toNumber();
}

export function formatCurrency(value, currency) {
    if (!currency)
        currency = 'usd';

    if (!value)
        value = 0;
    const formatted = new Intl.NumberFormat(getLang(), {
        style: 'currency',
        currency: currency,
        currencyDisplay: 'narrowSymbol'
    }).format(value);

    return formatted;
}

function truncate(number, digits) {
    return Math.trunc(number * Math.pow(10, digits)) / Math.pow(10, digits);
}

export function formatONE(value = 0, digits = 4) {
    return new Intl.NumberFormat(getLang(), {
        minimumFractionDigits: digits,
        maximumFractionDigits: digits

    }).format(truncate(value, digits));
}

export function formatAPR(value = 0, digits = 2) {
    return new Intl.NumberFormat(getLang(), {
        minimumFractionDigits: digits,
        maximumFractionDigits: digits

    }).format(truncate(value, digits));
}

