import Header from './components/Header';
import Footer from './components/Footer';
import Body from './components/Body';
import { useEffect, useState } from "react";
import Web3 from 'web3';
import detectEthereumProvider from "@metamask/detect-provider";
import { setAddress, setChain, reset, setAvailableToStake, getEpoch, getDelegatorData, getDelegatorBalance, setIsConnected } from './state/walletSlice';
import { getExchangeRate, setCurrency, setExchangeRate } from './state/currencySlice';
import { useSelector, useDispatch } from 'react-redux';
import { setGasPrice, setMaxGas } from './state/harmonySlice';
import SwitchNetwork from './components/SwitchNetwork';
import { setFormatedTxs, getTransactions, setTxLoaded } from './state/transactionsSlice';
import Transactions from './components/Transactions';
import { useCookies } from 'react-cookie';
import ReactGA from 'react-ga4';

function App() {

  const [gaInit, setGaInit] = useState(false);
  const [ethereumProvider, setEthereumProvider] = useState(null);
  const [cookies, setCookie] = useCookies(['txRcpts', 'currency']);
  const [web3, setWeb3] = useState(null);
  const [showAccount, setShowAccount] = useState(false);
  const { ethereumAddress, isConnected, availableToStake, balance, epoch, stakablePending, chainId, isFindora, isHarmony } = useSelector((state) => state.wallet);
  const { exchangeRate, currency } = useSelector((state) => state.currency);
  const { gasLimit, gasPrice, maxGas } = useSelector((state) => state.harmony);
  const { transactions, txLoaded, txReceipts, gettingTxs } = useSelector((state) => state.transactions)
  const dispatch = useDispatch();

  const { formatedTxs } = useSelector((state) => state.transactions)

  useEffect(() => {
    if (!gaInit) {
      console.log('ga init')
      ReactGA.initialize('G-BSM5TVLXKX');
      ReactGA.send("pageview");
      setGaInit(true);
    }
  }, [gaInit, setGaInit])

  useEffect(() => {

    if (!ethereumProvider) {
      async function getProvider() {
        const provider = await detectEthereumProvider();

        if (provider !== window.ethereum) {
          console.error("Do you have multiple wallets installed?");
        }

        if (!provider) {
          console.error("No Ethereum provider found.");
          // to-do - onboarding - download wallet
          return;
        }
        //console.log(provider)
        setEthereumProvider(provider);
      }

      getProvider();
    } else {
      let wallet = '';
      if (ethereumProvider.isMetaMask) {
        if (ethereumProvider.isBraveWallet)  // Brave Wallet always sets isMetaMask to true for compatability
          wallet = 'BraveWallet'
        else
          wallet = 'MetaMask'
      }

      if (ethereumProvider.isCoinbaseWallet) {
        wallet = 'CoinbaseWallet'
      }

      console.log('Found wallet ', wallet);

      ReactGA.event({
        category: 'User',
        action: wallet,
        nonInteraction: true
      });
    }
  }, [ethereumProvider])

  useEffect(() => {
    if (cookies.currency)
      dispatch(setCurrency(cookies.currency))
  }, [currency, cookies])

  useEffect(() => {

    async function getGas() {
      if (web3) {
        let _gasPrice = await web3.eth.getGasPrice();
        dispatch(setGasPrice(_gasPrice));
        console.log("Gas Price: " + _gasPrice);
        dispatch(setMaxGas((_gasPrice / 1e18) * gasLimit));
      }
    }

    getGas();

  }, [web3, gasPrice, maxGas, gasLimit, dispatch])

  useEffect(() => {
    const timer = setInterval(() => {
      //console.log('Update exchange rate.');
      dispatch(getExchangeRate(currency));
    }, 10000);

    return () => clearInterval(timer);
  }, [dispatch, currency]);

  useEffect(() => {
    const available = Number(balance) + Number(stakablePending);
    if (available !== availableToStake)
      dispatch(setAvailableToStake(available));

  }, [balance, availableToStake, dispatch, stakablePending])

  useEffect(() => {
    const timer = setInterval(() => {
      //console.log('Update balance.');
      getDelegatorBalance(ethereumAddress, web3);
    }, 20000);

    return () => clearInterval(timer);
  }, [dispatch, web3, ethereumAddress]);

  const handleAccountsChanged = (accounts) => {
    console.log('Account changed to ', accounts[0])
    dispatch(reset());
    dispatch(setTxLoaded(false));
    dispatch(setFormatedTxs([]));
    if (accounts.length === 0) {
      console.error("No accounts found.");
    } else {
      if (ethereumAddress !== accounts[0]) {
        dispatch(getDelegatorBalance(accounts[0], web3));
        dispatch(setAddress(accounts[0]));
        dispatch(setIsConnected(true));
        dispatch(getTransactions());
      }
    }
  };

  useEffect(() => {
    const timer = setInterval(() => {
      //console.log('Update delegator data.');
      dispatch(getDelegatorData(ethereumAddress, web3));
    }, 50000);

    return () => clearInterval(timer);
  }, [dispatch, ethereumAddress]);

  const handleChainIdChanged = (chainId) => {
    console.log("chainIdChanged: " + chainId);
    dispatch(setExchangeRate(0));
    dispatch(setChain(chainId));
    dispatch(getDelegatorBalance(ethereumAddress, web3));
    dispatch(getDelegatorData(ethereumAddress));
  }

  const signIn = async () => {

    if (!ethereumProvider)
      window.message('No wallet found.')

    const w3 = new Web3(ethereumProvider);

    setWeb3(w3);

    const _chainId = await ethereumProvider.request({ method: 'eth_chainId' });

    handleChainIdChanged(_chainId);

    // MetaMask events
    ethereumProvider.on('connect', (connectInfo) => {
      console.log('Wallet connected:');
      console.log(connectInfo);
    });

    ethereumProvider.on("accountsChanged", (accounts) => {
      dispatch(getDelegatorBalance(accounts[0], w3));
      handleAccountsChanged(accounts);
    });

    ethereumProvider.on("disconnect", () => {
      console.log("disconnect");
      dispatch(reset());
      window.location.reload(false);
    });

    ethereumProvider.on("chainChanged", handleChainIdChanged);

    ethereumProvider.on('message', (message) => {
      //console.log('Received MetaMask Message:');
      //console.log(message);
    });

    ethereumProvider
      .request({ method: "eth_requestAccounts" })
      .then(async (params) => {
        dispatch(getDelegatorBalance(params[0], w3));
        handleAccountsChanged(params);
      })
      .catch((err) => {
        if (err.code === 4001) {
          console.error("Please connect to Wallet.");
        } else {
          console.error(err);
        }
      });
  };

  const connectWallet = () => {
    console.log('Connect wallet')
    ReactGA.event({
      category: 'User',
      action: 'ConnectWallet'
    });
    signIn();
  };

  return (

    <div className="App">
      <div className='main-content'>
        <Header showAccount={(show) => setShowAccount(show)} walletBtnOnClick={() => connectWallet()} />

        {isConnected && isHarmony &&
          <Body ReactGA={ReactGA} className="main-body" web3={web3} showAccount={showAccount} setShowAccount={(show) => setShowAccount(show)} />
        }
        {(isConnected && (Number(chainId) !== 0x63564c40)) &&
          <SwitchNetwork web3={web3} />
        }
        {isConnected && isHarmony &&
          <Transactions formatedTxs={formatedTxs} />
        }
        {/*
      {(txReceipts.length > 0) &&
        <button onClick={() => getTransactionData(ethereumAddress, transactions, txReceipts)} >Click me</button>
      }
    */}
      </div>

      <Footer />

    </div>

  );
}

export default App;
