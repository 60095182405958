import React from 'react';
import { formatCurrency, formatONE } from '../utils/numberFormat';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { useSelector } from 'react-redux';
import ReactTooltip from 'react-tooltip';

const Rewards = ({ claim, compound }) => {
    const { unclaimedRewards, totalStake, symbol } = useSelector((state) => state.wallet);
    const { currency, exchangeRate } = useSelector((state) => state.currency);
    const { netApr } = useSelector((state) => state.validator);
    const yearlyEst = totalStake * (netApr / 100);
    const monthlyEst = yearlyEst / 12;
    const weeklyEst = yearlyEst / 52;
    const dailyEst = yearlyEst / 365;

    return (
        <div className="column">
            <ReactTooltip />
            <div className="box">
                <h3>Unclaimed Rewards</h3>
                <h2>{formatONE(unclaimedRewards)} {symbol}</h2>
                <h4>{formatCurrency(unclaimedRewards * exchangeRate, currency)}</h4>
                <button className="btn" onClick={claim} data-tip="Claim earned rewards.">
                    <FontAwesomeIcon icon={solid('coins')} /> Claim</button>
                <button className="btn" onClick={compound}  data-tip="Claim earned rewards and deposit in to staking pool.">
                    <FontAwesomeIcon icon={solid('repeat')} /> Compound</button>
                <br />
                <h3>Estimated Rewards</h3>
                <table style={{ marginLeft: "30px", marginRight: "30px", width: "75%" }}>
                    <tbody>
                        <tr><td style={{ textAlign: "right", width: "35%" }}>Daily :</td><td style={{ textAlign: "right", width: "65%" }}>{formatONE(dailyEst, 4)} {symbol}</td></tr>
                        <tr><td style={{ textAlign: "right" }}>Weekly :</td><td style={{ textAlign: "right" }}>{formatONE(weeklyEst, 4)} {symbol}</td></tr>
                        <tr><td style={{ textAlign: "right" }}>Monthly :</td><td style={{ textAlign: "right" }}>{formatONE(monthlyEst, 4)} {symbol}</td></tr>
                        <tr><td style={{ textAlign: "right" }}>Yearly :</td><td style={{ textAlign: "right" }}>{formatONE(yearlyEst, 4)} {symbol}</td></tr>
                    </tbody>
                </table>
            </div>
        </div >
    )
}

export default Rewards
