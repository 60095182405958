import { configureStore } from "@reduxjs/toolkit";
import walletReducer from './walletSlice';
import currencyReducer from './currencySlice';
import validatorReducer from './validatorSlice';
import harmonyReducer from './harmonySlice';
import statusReducer from "./statusSlice";
import transactionsReducer from './transactionsSlice'

export const store = configureStore({
    reducer: {
        wallet: walletReducer,
        currency: currencyReducer,
        validator: validatorReducer,
        harmony: harmonyReducer,
        status: statusReducer,
        transactions: transactionsReducer,
    },
});