import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { useSelector, useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { setDialogOpen } from '../../state/statusSlice';

const StatusDialog = ({ close }) => {
  const dispatch = useDispatch();
  const { mainText, bodyText, tasks, status, dialogOpen, statusMessage } = useSelector((state) => state.status);

  function closeStatusDialog() {
    dispatch(setDialogOpen(false));
    close();
  }

  useEffect(() => {
    let timer;
    if (dialogOpen && (status === 'complete' || status === 'failed'))
      timer = setInterval(() => {
        closeStatusDialog();
      }, 5000);

    return () => {
      if (timer)
        clearInterval(timer);
    }

  }, [status, dispatch, dialogOpen])

  function getIcon(_status) {
    //console.log('Status: ' + _status)

    switch (_status) {
      case 'pending':
        return <FontAwesomeIcon style={{ color: "#00AEE9" }} spin={true} icon={solid('spinner')} />
      case 'complete':
        return <FontAwesomeIcon style={{ color: "green" }} icon={solid('face-grin-wide')} />
      case 'failed':
        return <FontAwesomeIcon style={{ color: "red" }} icon={solid('face-frown')} />
      default:
        return <FontAwesomeIcon style={{ color: "red" }} icon={solid('face-meh')} />
    }
  }
  const MessageLine = ({ description, status }) => {
    return (
      <>
        <div style={{ display: "inline-block", width: "90%" }}>{description}</div>
        <div style={{ display: "inline-block", width: "10%", textAlign: "right" }}>{getIcon(status)}</div>
      </>
    )
  }
  return (
    <>
      <div className='overlay-header' style={{ color: "white", padding: "10px", backgroundColor: "black", border: "2px solid black", borderTopLeftRadius: "20px", borderTopRightRadius: "20px" }}>
        <div> <FontAwesomeIcon size="xl" style={{ cursor: "pointer", color: "##FF6F00", float: "right" }} icon={solid('circle-xmark')} onClick={() => close()} /></div>
        <div style={{ color: "#00AEE9" }}>{mainText} {getIcon(status)}</div>
      </div>
      <div id='overlay-content' style={{ textAlign: "left", border: "3px solid black", backgroundColor: "rgb(57, 54, 54)", color: "white" }}>
        <div style={{ padding: "5px" }}>{bodyText}</div>
        <div style={{ display: "inline-block", padding: "5px", width: "95%" }}>
          {tasks &&

            tasks.map(({ id, description, status }) => (
              <MessageLine key={id} description={description} status={status} />
            ))
          }
        </div>
        {status &&
          <div style={status === 'complete' ? { color: 'green', padding: "5px" } : { color: 'red', padding: "5px" }}>{statusMessage}</div>
        }
      </div>
      <div style={{ padding: "10px", fontSize: "small", textAlign: "left", backgroundColor: "black", border: "2px solid black", borderBottomLeftRadius: "20px", borderBottomRightRadius: "20px" }}>
      </div>
    </>
  )
}

export default StatusDialog