import { createSlice } from "@reduxjs/toolkit";
import { getAllTransactions, getTransactionReceipts, getTransactionReceipt, processReceipt } from '../utils/harmony';

const initialState = {
    transactions: [],
    txLoaded: false,
    txReceipts: [],
    formatedTxs: [],
    gettingTxs: false,
};

const transactionsSlice = createSlice({
    name: 'transactions',
    initialState,
    reducers: {
        setTransactions: (state, action) => {
            state.transactions = action.payload;
        },
        setTxLoaded: (state, action) => {
            state.txLoaded = action.payload;
        },
        setTxReceipts: (state, action) => {
            state.txReceipts = action.payload;
        },
        appendTxReceipt: (state, action) => {
            state.txReceipts = [...state.txReceipts].concat(action.payload);
        },
        setFormatedTxs: (state, action) => {
            state.formatedTxs = action.payload;
        },
        appendFormatedTx: (state, action) => {
            state.formatedTxs = [...state.formatedTxs].concat(action.payload);
        },
        setGettingTxs: (state, action) => {
            state.gettingTxs = action.payload;
        },
    }
});

export const { setTransactions, setTxLoaded, setTxReceipts, appendTxReceipt, setFormatedTxs, appendFormatedTx, setGettingTxs } = transactionsSlice.actions;

export const getTransactions = () => async (dispatch, getState) => {
    const { gettingTxs } = getState().transactions;
    if (gettingTxs)
        return;

    dispatch(setGettingTxs(true));
    dispatch(setFormatedTxs([]));
    dispatch(setTransactions([]));
    

    const { ethereumAddress } = getState().wallet;
    const tx = await getAllTransactions(ethereumAddress)
    dispatch(setTransactions(tx));
    if (tx.length > 0) {
        const { txReceipts } = getState().transactions;
        getTransactionReceipts(tx, dispatch, txReceipts);
    }
    dispatch(setTxLoaded(true));
    dispatch(setGettingTxs(false));
}

export const addTxFromReceipt = (receipt, web3) => async (dispatch, getState) => {
    console.log('addTxFromReceipt');
    console.log(receipt);
    const tx = await web3.eth.getTransaction(receipt.transactionHash);
    //processReceipt(tx, receipt, dispatch);
    getTransactionReceipt(tx.hash).then((receipt) => {
        processReceipt(tx, receipt.result, dispatch)
        dispatch(appendTxReceipt(receipt.result))
    })
}

export default transactionsSlice.reducer