import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { formatONE } from '../../utils/numberFormat';
import { useSelector, useDispatch } from 'react-redux';
import { delegate } from '../../utils/staking';
import { openStatusDialog, updateStatusDialog } from '../../state/statusSlice';

const Deposit = ({ ReactGA, close, web3 }) => {
    const dispatch = useDispatch();
    const { availableToStake, ethereumAddress } = useSelector((state) => state.wallet);
    const validatorAddress = useSelector((state) => state.validator.address);
    const { gasLimit, gasPrice, maxGas } = useSelector((state) => state.harmony);
    const [amount, setAmount] = useState(Math.floor(availableToStake - maxGas));

    //document.getElementById("amount").focus();

    function balanceClick() {
        // document.getElementById("amount").value = parseFloat(Math.floor(availableToStake - maxGas)) || 0;
        setAmount(parseFloat(Math.floor(availableToStake - maxGas)) || 0);
    }

    function updateStatus(_statusParams, _receipt) {
        dispatch(updateStatusDialog(_statusParams, _receipt, web3));
    }

    function deposit() {
        console.log('Deposit: ' + amount);
        if (amount < 100) {
            alert('The minimum deposit amount is 100 ONE.');
            return;
        }
        let _statusParams = {
            ready: true,
            headerText: 'Depositing ONE...',
            bodyText: 'Please sign staking transaction.',
            status: 'pending',
            statusMessage: '',
            tasks: [{
                id: 0,
                description: 'Depositing ' + amount + ' ONE.',
                status: 'pending'
            }]
        };
        dispatch(openStatusDialog(_statusParams));

        delegate(web3, ethereumAddress, amount, validatorAddress, { gasPrice: gasPrice, gasLimit: gasLimit }, _statusParams, updateStatus);

        ReactGA.event({
            category: 'User',
            action: 'Deposit'
          });
    }

    return (
        <>
            <div className='overlay-header' style={{ color: "white", backgroundColor: "black", border: "2px solid black", borderTopLeftRadius: "20px", borderTopRightRadius: "20px" }}>
                <div style={{ width: "23%", display: "inline-block" }}>

                </div>
                <div style={{ textAlign: "center", width: "50%", display: "inline-block", color: "#00AEE9" }}>
                    <h3>Deposit ONE</h3>
                </div>
                <div style={{ width: "23%", display: "inline-block", padding: "5px" }}>
                    <FontAwesomeIcon size="xl" style={{ cursor: "pointer", color: "##FF6F00", float: "right" }} icon={solid('circle-xmark')} onClick={() => close()} />
                </div>
            </div>
            <div style={{ content: "", display: "table", clear: "both" }}></div>

            <div id='overlay-content' style={{ textAlign: "center", border: "3px solid black", backgroundColor: "rgb(57, 54, 54)", color: "white" }}>


                <input id="amount" type="number" value={amount} onChange={(e) => setAmount(e.target.value)} style={{ width: "90%", color: "white", backgroundColor: "rgb(57, 54, 54)", margin: "10px", fontSize: "large", fontWeight: "bold", border: "solid black", borderRadius: "5px", padding: "5px", textAlign: "center" }} />

                <div onClick={() => balanceClick()} style={{ margin: "10px", padding: "5px", fontSize: "small", cursor: "pointer", width: "90%" }}>
                    <div style={{ display: "inline-block", width: "30%", textAlign: "left" }}>Available to Deposit:</div>
                    <div style={{ display: "inline-block", width: "70%", textAlign: "right" }}>{formatONE(availableToStake)} ONE</div>
                </div>

                <div style={{ margin: "10px", padding: "5px", fontSize: "small", width: "90%" }}>
                    <div style={{ display: "inline-block", width: "30%", textAlign: "left" }}>Network Fee:</div>
                    <div style={{ display: "inline-block", width: "70%", textAlign: "right" }}>{formatONE(maxGas, 5)} ONE</div>
                </div>
                <div style={{ margin: "10px", padding: "5px" }}>
                    <button className="btn" onClick={() => deposit()} style={{ width: "100%", height: "50px", cursor: "pointer", margin: "auto" }}><FontAwesomeIcon icon={solid('circle-plus')} /> Deposit</button>
                </div>
            </div>
            <div style={{ padding: "10px", fontSize: "small", textAlign: "left", backgroundColor: "black", border: "2px solid black", borderBottomLeftRadius: "20px", borderBottomRightRadius: "20px" }}>
                <p style={{ color: "white" }}>DK Validator is a non-custodial staking service provider. All cryptocurrency delegated to DK Validator remain under the sole control of the wallet holder.</p>
                <p style={{ color: "white" }}>Rewards are distributed by the Harmony blockchain network every sixty seconds and are subject to a 5% operator fee.</p>
                <p style={{ color: "red" }}><b>Note:</b> Withdrawing deposited ONE is subject to an unlocking period of seven epochs, which is approximately five days depending on block time.</p>
            </div>
        </>
    )
}

export default Deposit