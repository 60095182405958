import { React, useEffect } from 'react'
import { useSelector } from 'react-redux'

const ValidatorSelect = ({ setValidator, setWithdrawable, selectedValidator }) => {
    const { delegations } = useSelector((state) => state.wallet);

    const updateSelected = (index) => {
        console.log(index)
        setValidator(delegations[index].validatorAddress);
        setWithdrawable(delegations[index].amount);
    }

    useEffect(() => {
        if (delegations.length < 1)
            return;

        let select = document.getElementById("delegates");
        // remove any existing options
        while (select.hasChildNodes()) {
            select.removeChild(select.firstChild);
        }
        // add options from currencies array
        delegations.forEach((currentValue, index, arr) => {
            let option = document.createElement("OPTION");
            option.value = index;
            if (currentValue.validatorName)
                option.text = currentValue.validatorName.substring(0, 48)
            else
                option.text = currentValue.validatorAddress;
            if (selectedValidator === delegations[index].validatorAddress) {
                option.selected = true;
                updateSelected(index);
            }
            select.add(option);
        })

    }, [delegations, selectedValidator]);

    return (

        <select name="delegates" id="delegates" style={{
            color: "#00AEE9",
            backgroundColor: "rgb(57, 54, 54)",
            border: "none",
            cursor: "pointer"
        }}
            onChange={(e) => updateSelected(e.target.value)}
        />

    )
}

export default ValidatorSelect