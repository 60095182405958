import { abi } from "../abi/staking-abi";
import { HarmonyAddress } from "@harmony-js/crypto";

var stakingContract;

const contractAddress = "0x00000000000000000000000000000000000000FC";
var gasLimit = 60000;
gasLimit = '0x' + gasLimit.toString(16);
var gasPrice = 150000000000;

function getContract(web3) {
    stakingContract = new web3.eth.Contract(abi, contractAddress);
}

async function setGasPrice(web3, inputGas) {
    let _gasPrice = inputGas;
    if (!inputGas)
        _gasPrice = await web3.eth.getGasPrice();
    gasPrice = '0x' + Number(_gasPrice).toString(16);
}

export function claimRewards(web3, address, updateStatusDialog, statusParams, _gasPrice) {
    getContract(web3);
    setGasPrice(web3, _gasPrice);

    let _address = new HarmonyAddress(address).checksum;
    claim(_address, statusParams, updateStatusDialog);
}

function claim(_address, statusParams, updateStatusDialog) {
    let params = JSON.parse(JSON.stringify(statusParams));

    stakingContract.methods.CollectRewards(_address).send({
        from: _address,
        gasPrice,
        gasLimit,
    }).then((result) => {
        params.tasks[0].status = 'complete';
        params.status = 'complete';
        params.statusMessage = 'Staking transaction complete.'
        updateStatusDialog(params, result);
    },
        (error) => {
            params.tasks[0].status = 'failed';
            params.status = 'failed';
            params.statusMessage = 'Transaction failed: ' + String(error.message).replace('MetaMask Tx Signature:', '');
            updateStatusDialog(params);
        });
}

export async function compound(web3, _address, validatorAddress, amount, statusParams, updateStatusDialog, _gasPrice) {
    getContract(web3);
    setGasPrice(web3, _gasPrice);
    let params = JSON.parse(JSON.stringify(statusParams));

    stakingContract.methods.CollectRewards(_address).send({
        from: _address,
        gasPrice,
        gasLimit,
    }).then((result) => {
        params.tasks[0].status = 'complete';
        params.status = 'complete';
        params.statusMessage = 'Staking transaction complete.'
        updateStatusDialog(params, result);

        delegate(web3, _address, amount, validatorAddress, { gasPrice: gasPrice, gasLimit: gasLimit }, params, updateStatusDialog, 1)
    },
        (error) => {
            params.tasks[0].status = 'failed';
            params.tasks[1].status = 'failed';
            params.status = 'failed';
            params.statusMessage = 'Transaction failed: ' + String(error.message).replace('MetaMask Tx Signature:', '');
            updateStatusDialog(params);
        })
}

export function delegate(web3, address, amount, validatorAddress, gasValues, statusParams, updateStatusDialog, taskId = 0) {
    getContract(web3);
    setGasPrice(web3);

    let params = JSON.parse(JSON.stringify(statusParams));

    if (gasValues) {
        if (gasValues.gasPrice && gasValues.gasPrice > 0)
            gasPrice = '0x' + Number(gasValues.gasPrice).toString(16);;

        if (gasValues.gasLimit && gasValues.gasLimit > 0)
            gasLimit = '0x' + Number(gasValues.gasLimit).toString(16);;
    }

    let _address = new HarmonyAddress(address).checksum;
    let _amount = '0x' + Number(amount * 1e18).toString(16);

    console.log('Delegating...');
    console.dir(_amount);

    stakingContract.methods.Delegate(_address, new HarmonyAddress(validatorAddress).checksum, _amount).send({
        from: _address,
        gasPrice,
        gasLimit,
    }).then((result) => {
        params.tasks[taskId].status = 'complete';
        params.status = 'complete';
        params.statusMessage = 'Staking transaction complete.'
        updateStatusDialog(params, result);
    },
        (error) => {
            params.tasks[taskId].status = 'failed';
            params.status = 'failed';
            params.statusMessage = 'Transaction failed: ' + String(error.message).replace('MetaMask Tx Signature:', '');
            updateStatusDialog(params);
        });
}

export async function undelegate(web3, address, amount, validatorAddress, statusParams, updateStatusDialog, _gasPrice) {
    getContract(web3);
    setGasPrice(web3, _gasPrice);
    let params = JSON.parse(JSON.stringify(statusParams));
    //console.log('Udelegating...');
    let _address = new HarmonyAddress(address).checksum;
    // let _amount = new BN(String(amount * 1e18));
    let _amount = '0x' + Number(amount * 1e18).toString(16);
    console.log('Undelegate: ' + _amount)
    stakingContract.methods.Undelegate(_address, new HarmonyAddress(validatorAddress).checksum, _amount).send({
        from: _address,
        gasPrice,
        gasLimit,
    }).then((result) => {
        params.tasks[0].status = 'complete';
        params.status = 'complete';
        params.statusMessage = 'Staking transaction complete.'
        //console.log(result)
        updateStatusDialog(params, result);
    },
        (error) => {
            params.tasks[0].status = 'failed';
            params.status = 'failed';
            params.statusMessage = 'Transaction failed: ' + String(error.message).replace('MetaMask Tx Signature:', '');
            updateStatusDialog(params);
        });

}