import logo from "../assets/DK Validator Transparent Logo.png";
import harmonyLogo from "../assets/harmony-one-logo.png";
import findoraLogo from "../assets/Findora_symbol@2x.png";
import { solid /*, regular, brands */ } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CurrencySelect from './CurrencySelect';
import { formatONE } from '../utils/numberFormat';
import { useSelector } from 'react-redux'

const Header = ({ walletBtnOnClick, showAccount }) => {
    const { ensName, isConnected, isHarmony, isFindora, ethereumAddress } = useSelector((state) => state.wallet);
    const exchangeRate = useSelector((state) => state.currency.exchangeRate);
    const addr = ethereumAddress;
    let addressTxt = '';
    let addrLen = 0;
    let coinLogo = harmonyLogo;

    if (isHarmony)
        coinLogo = harmonyLogo;

    if (isFindora)
        coinLogo = findoraLogo;

    if (addr)
        addrLen = addr.length;
    if (addr && addrLen > 10) {
        addressTxt = addr.substring(0, 6) + '...' + addr.substring(addrLen - 5, addrLen);
    }

    return (
        <div className="header">
            <div>
                <a href="https://dkvalidator.one" target='_blank' rel="noreferrer">
                    <img className="logo" src={logo} alt="DK Validator - Secure Independent Staking" width="300px" />
                </a>
                <div className="headerleft">
                    {exchangeRate !== 0 &&
                        <button className="walletbutton">
                            <img className="harmonylogo" src={coinLogo} alt="Harmony ONE" width="12px" />{formatONE(exchangeRate, 5)} <CurrencySelect />
                        </button>
                    }


                    {isConnected ?
                        <button className="walletbutton" onClick={() => showAccount(true)}>
                            <FontAwesomeIcon icon={solid('wallet')} /> {ensName ? ensName : addressTxt}
                        </button>
                        :
                        <button className="walletbutton" onClick={walletBtnOnClick} style={{ color: "black", backgroundColor: "#FF6F00", fontWeight: "bold" }}>{'Connect Wallet'}</button>
                    }
                </div>
            </div>
        </div>
    )
}

export default Header
