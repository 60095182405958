import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    currency: 'usd',
    exchangeRate: 0,
    supportedCurrencies: ['usd', 'gbp', 'eur', 'pln'],
    supportedCryptos: ['harmony', 'findora'],
}

const currencySlice = createSlice({
    name: 'currency',
    initialState,
    reducers: {
        setCurrency: (state, action) => {
            state.currency = action.payload;
        },
        setExchangeRate: (state, action) => {
            state.exchangeRate = action.payload;
        },
    }
});

export default currencySlice.reducer

export const { setCurrency, setExchangeRate } = currencySlice.actions

export const getExchangeRate = (_currency) => async (dispatch, getState) => {
    const { symbol, isFindora, isHarmony } = getState().wallet;
    const { currency } = getState().currency;

    if (!_currency)
        _currency = currency;

    if (!isFindora && !isHarmony) {
        dispatch(setExchangeRate(0));
        return;
    }
    let _crypto = 'harmony';

    if (symbol === 'FRA')
        _crypto = 'findora';
    try {
        const response = await fetch('https://api.coingecko.com/api/v3/simple/price?ids=' + _crypto + '&vs_currencies=' + _currency);
        if (!response.ok)
            return;

        const responseJson = await response.json();

        dispatch(setCurrency(_currency));
        dispatch(setExchangeRate(responseJson[_crypto][_currency]));
    } catch (e) {
        console.log('Error getting currency price. ', e)
    }
};
