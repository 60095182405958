import { useState, useEffect } from 'react'
import { formatCurrency, formatONE, formatAPR } from '../utils/numberFormat';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { useSelector } from 'react-redux';
import ReactTooltip from 'react-tooltip';

const Stake = ({ ReactGA, delegate, undelegate }) => {
    const { symbol, availableToStake, totalStake, isHarmony, isFindora } = useSelector((state) => state.wallet);
    const { apr, commission } = useSelector((state) => state.validator);
    const { currency, exchangeRate } = useSelector((state) => state.currency);
    const [canStake, setCanStake] = useState(false);
    const [canWithdraw, setCanWithdraw] = useState(false);

    useEffect(() => {
        if (!canStake && availableToStake > 100)
            setCanStake(true);

        if (canStake && availableToStake <= 100)
            setCanStake(false);
    }, [availableToStake, canStake])

    useEffect(() => {
        setCanWithdraw(Number(totalStake) > 0)
    }, [canWithdraw, totalStake])


    return (
        <div className="column">
            <ReactTooltip />
            <div className="box">
                {isHarmony &&
                    <h3>Stake Harmony ONE</h3>
                }
                {isFindora &&
                    <h3>Stake Findora FRA</h3>
                }
                <div style={{ margin: "auto", height: "100px", width: "100px", border: "solid 2px", borderRadius: "50%", borderColor: "#00AEE9", backgroundColor: "#00AEE9", color: "white", textAlign: "center", verticalAlign: "middle" }}>
                    <div style={{ height: "25%" }}></div>
                    <div style={{ height: "50%", fontSize: "large", fontWeight: "bold" }} data-tip="DK Validator APR - based on 30 epoch average.">
                        {formatAPR(apr)}%
                        <br />
                        APR
                    </div>
                    <div style={{ height: "25%" }}></div>
                </div>
                <h3>Available To Stake</h3>
                <h2 className={`avlstake ${canStake ? "" : "notavlstake"}`} data-tip="Total ONE balance available to deposit.">{formatONE(availableToStake)} {symbol}</h2>
                <h4 >{formatCurrency(availableToStake * exchangeRate, currency)}</h4>
                <div>
                    <button className="btn" onClick={delegate} disabled={!canStake} data-tip="Deposit ONE into staking pool. Minimum deposit is 100 ONE."><FontAwesomeIcon icon={solid('circle-plus')} /> Deposit</button>
                    <button className="btn" onClick={undelegate} disabled={!canWithdraw} data-tip="Withdraw ONE from staking pool."><FontAwesomeIcon icon={solid('circle-minus')} /> Withdraw</button>
                </div>
                <div data-tip="Validator commission fee is deducted from rewards.">{commission}% Commission Fee</div>
            </div>
        </div>
    )
}

export default Stake
