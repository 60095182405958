import { createSlice } from "@reduxjs/toolkit";
import { getValidatorInformation } from "../utils/harmony";
import { toONE, formatAPR } from "../utils/numberFormat";

const initialState = {
    address: 'one1w7nvheulzwprf9d9a3r8sqtv5q47qlqx7kured',
    name: '',
    apr: 0,
    commission: 0,
    netApr: 0,
    totalStake: 0,
    lifetimeRewards: 0
};

const validatorSlice = createSlice({
    name: 'validator',
    initialState,
    reducers: {
        setAddress: (state, action) => {
            state.address = action.payload
        },
        setName: (state, action) => {
            state.name = action.payload;
        },
        setApr: (state, action) => {
            state.apr = action.payload
        },
        setCommission: (state, action) => {
            state.commission = action.payload
        },
        setNetApr: (state, action) => {
            state.netApr = action.payload
        },
        setTotalStake: (state, action) => {
            state.totalStake = action.payload
        },
        setLifetimeRewards: (state, action) => {
            state.lifetimeRewards = action.payload
        },
    }
});

export const { setAddress, setName, setApr, setCommission, setNetApr, setTotalStake, setLifetimeRewards } = validatorSlice.actions;

export default validatorSlice.reducer

export const getValidatorData = (address) => async (dispatch, getState) => {


    const { isFindora, isHarmony } = getState().wallet;

    if (isFindora) {
        dispatch(getFindoraValidatorData());
        return;
    }

    if (isHarmony) {
        dispatch(getHarmonyValidatorData())
        return;
    }
};

export const getHarmonyValidatorData = () => async (dispatch, getState) => {

    const res = await getValidatorInformation('one1w7nvheulzwprf9d9a3r8sqtv5q47qlqx7kured');

    if (res.error)
        return;

    const validator = res.result;

    dispatch(setAddress('one1w7nvheulzwprf9d9a3r8sqtv5q47qlqx7kured'));
    dispatch(setName(validator.validator.name));
    dispatch(setApr(validator.lifetime.apr * 100));
    dispatch(setNetApr(validator.lifetime.apr - ((validator.lifetime.apr / 100) * validator.validator.rate)));
    dispatch(setCommission(validator.validator.rate * 100));
    dispatch(setTotalStake(toONE(validator["total-delegation"])));
    dispatch(setLifetimeRewards(toONE(validator.lifetime["reward-accumulated"])));
    dispatch(getAverageApr(validator.lifetime['epoch-apr']));
};

export const getFindoraValidatorData = () => async (dispatch) => {
    console.log('Getting Findora validator data.')
    let options = {
        method: 'GET',
        //mode: 'no-cors',
        headers: {
            'Content-Type': 'application/json'
        }

    }

    try {


        const res = await fetch('https://v2s5rvhoz5.execute-api.us-east-1.amazonaws.com/prod/listData?type=getPool&address=69E2B6C4C1122172E69AF48E0AEC36B7F7C8005A&key=2mwTEDr9zXJH323M&token=1660662403&app=FRA', options);
        console.log(res);
        if (!res.ok)
            return;

        const validator = await res.json();
        console.log(validator);

        dispatch(setAddress(validator.address));
        dispatch(setName(validator.name));
        dispatch(setApr(validator.currentNetApr));
        dispatch(setNetApr(validator.currentNetApr));
        dispatch(setCommission(validator.commission));
        dispatch(setTotalStake(validator.totalStake));
    } catch (e) {
        console.log('Error getting Findora validator');
    }
};

export const getAverageApr = (epochAprs) => async (dispatch) => {
    if (!epochAprs || epochAprs.length === 0)
        return;

    let sum = 0, count = 0, avgApr = 0;

    for (let i = 0; i < epochAprs.length; i++) {
        sum += Number(formatAPR(Number(epochAprs[i].apr) * 100, 4));
        count++;
    }

    if (count > 0) {
        avgApr = sum / count;
        dispatch(setApr(avgApr));
        dispatch(setNetApr(avgApr - ((avgApr / 100) * 5)));
    }

}