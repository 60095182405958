import { appendTxReceipt, appendFormatedTx } from '../state/transactionsSlice';
import moment from 'moment';
import abiDecoder from 'abi-decoder';
import { abi } from "../abi/staking-abi";

const apiUrls = ['https://rpc.s0.t.hmny.io', 'https://api.harmony.one'];
abiDecoder.addABI(abi);

export const getAllTransactions = async (address) => {
    if (!address)
        return [];
    console.log('Getting txs for ', address)
    let finished = false;
    let pageIndex = 0;
    let transactions = [];

    while (!finished) {
        console.log('Getting transaction data page:', pageIndex)
        const response = await getTransactionsHistory(address, pageIndex);
        if (response.result.transactions && response.result.transactions.length > 0) {
            if (response.result.transactions.length < 1000)
                finished = true;
            transactions = transactions.concat(response.result.transactions)
            pageIndex += 1;
        }
        else
            finished = true
    }

    return transactions;
}

export const getTransactionReceipts = async (transactions, dispatch, receipts) => {
    console.log('Getting tx receipts..')
    const stakingTxs = transactions.filter((tx) => tx.to === 'one1qqqqqqqqqqqqqqqqqqqqqqqqqqqqqq8uuuycsy');

    stakingTxs.forEach(tx => {
        let receipt = [];

        if (receipts) {
            receipt = receipts.filter(rcpt => rcpt.hash === tx.hash)
        }
        if (receipt.length === 0)
            getTransactionReceipt(tx.hash).then((receipt) => {
                processReceipt(tx, receipt.result, dispatch)
                dispatch(appendTxReceipt(receipt.result))
            })

    });
}

export const processReceipt = async (tx, receipt, dispatch) => {
    //console.log('Process receipt..')
    //console.log(receipt)
    //console.log(tx)
    if ((receipt.status === 1 || receipt.status === true) && (tx.to === 'one1qqqqqqqqqqqqqqqqqqqqqqqqqqqqqq8uuuycsy' || tx.to === '0x00000000000000000000000000000000000000fc')) {
        //abiDecoder.addABI(abi);
        const decodedTx = abiDecoder.decodeMethod(tx.input);
        let value = '';
        let functionName = '';
        switch (decodedTx.name) {
            case 'Delegate': {
                //console.log(decoded)
                value = decodedTx.params[2].value / 1e18;
                functionName = 'Deposit';
                break;
            }
            case 'Undelegate': {
                //console.log(decoded)
                value = decodedTx.params[2].value / 1e18;
                functionName = 'Withdraw';
                break;
            }
            case 'CollectRewards': {
                //console.log(receipt)
                value = receipt.logs[0].data / 1e18;
                functionName = 'Claim Reward';
                break;
            }
            default: {
                //...
            }
        }
        /*
                const response = await fetch('https://api.coingecko.com/api/v3/coins/harmony/history?date=' + moment(tx.timestamp * 1000).format('DD-MM-YYYY') + '&localization=en');
                if (!response.ok)
                    return;
            
                const responseJson = await response.json();
        */
        let txTimeStamp = tx.timestamp;
        if (!txTimeStamp)
            txTimeStamp = Math.round(+new Date()/1000) // no timestamp on new tx???

        const fTx = {
            hash: tx.hash,
            timestamp: txTimeStamp,
            dateTime: moment(txTimeStamp * 1000).format('YYYY-MM-DD HH:mm:ss'),
            txType: 'Staking Precompile',
            function: functionName,
            amount: value,
            gasFee: receipt.gasUsed / 10000000,
            value: '..',
            //value: responseJson["market_data"]["current_price"],

        }
        //console.log(fTx)
        dispatch(appendFormatedTx(fTx));
    }
}

export const getDelegationsByDelegator = async (address) => {
    return await sendMessage("hmyv2_getDelegationsByDelegator", [address]);
}

export const getBalance = async (address) => {
    return await sendMessage("eth_getBalance", [address, "latest"]);
}

export const getEpoch = async () => {
    return await sendMessage("hmyv2_getEpoch", []);
}

export const getValidatorInformation = async (address) => {
    return await sendMessage("hmyv2_getValidatorInformation", [address]);
}

export const getTransactionsHistory = async (address, pageIndex = 0) => {
    const params = [
        {
            "address": address,
            "pageIndex": pageIndex,
            "pageSize": 1000,
            "fullTx": true,
            "txType": "ALL",
            "order": "DESC"
        }
    ]

    return await sendMessage("hmyv2_getTransactionsHistory", params);
}

export const getTransactionReceipt = async (txHash) => {

    return await sendMessage("hmyv2_getTransactionReceipt", [txHash]);
}

const sendMessage = async (method, params) => {
    const messageBody = createMessageBody(method, params);

    return sendRequest(messageBody);
}

const createMessageBody = (apiMethod, methodParams) => {
    const request = {
        jsonrpc: "2.0",
        id: 1,
        method: apiMethod,
        params: methodParams
    }

    const msg = {
        method: "post",
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify(request)
    }

    return msg;
}

const sendRequest = async (msg) => {
    for (let i = 0; i < apiUrls.length; i++) {
        try {
            const result = await fetch(apiUrls[i], msg);
            if (result.ok)
                return await result.json();
        } catch (e) {
            console.log('Error fetching data: ');
            console.log(e);
        }
    }

    return { error: 'Could not fetch data for message.' };
}

export const tokenNames = {
    'one19fce47zghum9fz09fzl9aklvr4jctrjeuf7fke': 'FIRA',
    'one1xz2uw4tmev5kenrwxc77qxmkpwsrrukel9ucc5': '1WBTC',
    'one1g9s60ajjugu7z3umqz6e8aetxhvsjcyut39hc5': 'MOCHI-LP',
    'one1afvfayllrzc6ru0fhtr7705x4d32mhrewz4c77': 'VIPER',
    'one1fcsnmm63zew7avm8ksw2d9uel9xqmhy2fqsc7l': 'VENOM-LP',
    'one1yscnp4dfha20ues6mj59p3ypdqka2qhx2cavs6': 'VENOM-LP',
    'one1yxzn9gf28zdy4yhup30my2gp68qerx929rv2ns': 'LINK',
    'one1pht5pkufh8768w4d7uukmkkhq2mw34h4yrrvh2': 'hMOCHI',
    'one1tvxfyrtcswxh720rjukgshzqvk4ean9j2umfg8': 'VENOM-LP',
    'one1enzvprntnx8lf0d6kzaaraghy89fwxdw490jgg': 'MOCHI-LP',
    'one1jcp9fqaaxtrytwpz5ksgqp9cf4n52d7tdrp2wz': 'VENOM-LP',
    'one1ckzrk0wehyf06k2cukcarun338hhrzw632e7wt': 'KNIGHT',
    'one1fnzrt4ae2474f4h0qttfh0mjvdyst0c37y5vma': '1ETH',
    'one1ch86m2zwjq4djtw5qx20pzp66jtrnvprjk78us': '3CRV',
    'one1gu0kda66ly3c5taz8w3rsc44j4csn7epatrzvl': 'USDS',
    'one1t2dvdsn3v57u2es2ay8ganc5qyty5f5s6ua9g6': 'wFarmer',
    'one1np293efrmv74xyjcz0kk3sn53x0fm745f2hsuc': '1USDC',
    'one16hger3enxp4gl6wf5vtxm8979g8yq7teqefrau': 'CLP',
    'one17v2cqwafmg5nwedtzcl8mwvw34kld5mpxpqfuf': 'DAIKI',
    'one1dxparek77d5scntpdvf4j7sfucvnagqnhhfaun': '1ETH',
    'one1td68ugafunzsnhgxl0fvpc7t3wzxuwv0ranhyn': 'EVO',
    'one1yttzkxdhqwfn8tth8dcctwmp998n4hqeylm9zm': 'stONE',
    'one17wzexwyq08ml2ycdvp0r32lkpyxes8kz0uw0nt': 'sWAGMI',
    'one1a7th6tunrsvh3k6lvarkvmapat9s6qee9kna05': '1DAI',
    'one18s4ch6vu2pvnpq0252njfu9c9p044w50gw3l6y': '1USDT',
    'one1jh89gltnq5v6jr00xrty0umanef4nd4war9hfn': 'LUNA',
    'one1yf8xfmqmmn3cwzn2d3mhah29q32qdrlvgu5xy3': 'UST',
    'one1wu4chyjdr9cs33wvjjpac7la0g26pfgnzsny7m': 'JEWEL-LP',
    'one1np9edx5wst6uuyfpe6crl9hltwelw8lwpagctd': 'FUZZ',
    'one1p26r259xj90e7e7sc32v96grshnyjl420na5yn': 'bscBUSD',
    'one1eutsnttk57w45cpppu37s88zgcz592pkn604v7': 'TRANQ',
    'one16azr8vv8eu96nx9dn035s6ujn3mgz5s4nnea3w': 'MIS',
    'one134mqf964fmkv8wgrd7crvs2kauhs6q4ulqdlsx': 'HLY',
    'one1hw2gvg86nn24fmu6xvd38m02nvvpl829gfxawd': 'wsWAGMI',
    'one1upj2dzv5ayuqy5x0aclgcr32chqfy32glsdusk': 'xVIPER',
    'one1adtemhx5nfa7k0eqtj0lvqrtkcus7yu0nk2y90': 'JEWEL-LP',
    'one1eanyppa9hvpr0g966e6zs5hvdjxkngn6jtulua': 'WONE',
    'one1remejzthh4er7jl6al4x30ehy33xs0az244ud3': 'VENOM-LP',
    'one1u9mwheravgdesjnnqd4emfwcx3q3aae5hw36l2': 'BUSD',
    'one1xqf9nuujk4gu4rze9j0kwm7d97dq4px9rfeyaw': '1MATIC',
    'one1dmmvtuck7u9elnhmw5aykqzt3m54qm435eyzuk': 'FUZZ-LP',
    'one1q8dj9jnc809zm9kdcnhdq730r8eaz8jugam3g7': 'FUZZ-LP',
    'one1qqqjsntadglfgqnen6rvdta6k7w5wfh5q9h9j3': 'VENOM-LP',
    'one1phrcc7d5avyqat2ur5t9ty395344sp55z47nzp': 'WAGMI',
    'one1w5tqvkzle2nnh7fvlq3m0dkc5quc58yewaqecj': 'JEWEL-LP',
    'one1wt93p34l543ym5r77cyqyl3kd0tfqpy0eyd6n0': 'JEWEL',

}


